const API_URL = "https://api.uat.checkup.dev.solidstudio.io";

export default {
    DEFAULT_REGION: "eu-west-2",
    cognito: {
        USER_POOL_ID: "eu-west-2_Psv64s5Pt",
        APP_CLIENT_ID: "1fh148dl3no086595qeu444rub",
        IDENTITY_POOL_ID: "eu-west-2:c5051cd6-0746-40fc-9f7e-f21f5f8c1823"
    },
    s3: {
        BUCKET: "tcup-uat-app-uploads"
    },
    apis: {
        usersApi: {
            URL: `${API_URL}/users-service`
        },
        questionsApi: {
            URL: `${API_URL}/questions-service`
        }
    }
};