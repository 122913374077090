export const createAction = (type, payload) => ({ type, payload });

export const CATEGORIES = {
    FETCH_CATEGORIES_START: 'FETCH_CATEGORIES_START',
    FETCH_CATEGORIES_SUCCESS: 'FETCH_CATEGORIES_SUCCESS',
    FETCH_CATEGORIES_ERROR: 'FETCH_CATEGORIES_ERROR'
}

export const SESSION_ID = {
    FETCH_SESSION_ID_START: 'FETCH_SESSION_ID_START',
    FETCH_SESSION_ID_SUCCESS: 'FETCH_SESSION_ID_SUCCESS',
}