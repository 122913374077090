import React from 'react';
import { Tree } from "antd";

const { TreeNode } = Tree;

class HierarchySelect extends React.Component {
    state = {
        expandedKeys: ['0-0-0-0', '0-1-0-0']
      };
    
      loop = (data, key, callback) => {
        data.forEach((item, index, arr) => {
          if (item.key === key || item.id === key) {
              return callback(item, index, arr);
          }
          if (item.children) {
              return this.loop(item.children, key, callback);
          }
        }); 
      };

      getSelectedKey = (treeData, teamId) => {
        let selectedKey; 
        this.loop(treeData, teamId, item => {
            selectedKey = item.key;
        })
        return selectedKey;
    }
    
      //Setting the selectedNoddID of the state with the id of the tree node selected
    handleOnSelect = (selectedKey, info) => {
        this.setState({ selectedKey: selectedKey[0] });
      //   let selectedNodeKey = selectedKey;
        if(!info.node.props.selected) {
          let selectedNode = {
            name: info.node.props.title,
            key: selectedKey.toString(),
            children: info.node.props.children,
            id: info.node.props.id,
          }
        // console.log("selecteNodeKey", selectedNodeKey);
        // this.setState({ selectedNodeKey });
          this.props.handleSelect(info.node.props);
        } else {
          this.props.handleSelect(null);
        }

      };

      render() {
        const loop = data =>
          data.map(item => {
            if (item.children && item.children.length) {
              return (
                <TreeNode key={item.key} 
                          title={item.name} 
                          id={item.id} 
                          className={this.props.treeNodeStyle}>
                  {loop(item.children)}
                </TreeNode>
              );
            }
            return <TreeNode key={item.key} 
                             title={item.name} 
                             id={item.id}
                             className={this.props.treeNodeStyle}
                            />;
          });

        return(<Tree
                    className={this.props.treeStyle}
                    defaultExpandedKeys={this.state.expandedKeys}
                    blockNode
                    selectedKeys={[this.props.selectedKey]}
                    onSelect={this.handleOnSelect}
                >
                    {loop(this.props.treeData)}
                </Tree>) 
      }
}

export default HierarchySelect;