import styles from "./Summary.module.less";

import React, { Component } from "react";
import { Divider } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";
import { Storage } from "aws-amplify";
import { Redirect } from "react-router";
import sizes from "react-sizes";
import isNull from "lodash/isNull";
import isEmpty from "lodash/isEmpty";
import Api from "../api/Api";
import TCup from "./tcup/TCup";
import { notificationWarning } from "../utils/notification/Notification";
import handleError from "../utils/handleError/HandleError";
import Spinner from "../utils/spinner/Spinner";
import withSessionId from "../../redux/hoc/withSessionId";
import LocalStorageProperties from "../utils/LocalStorageProperties";

import facebook from "./assets/facebook.svg";
import linkedin from "./assets/linkedin.svg";
import instagram from "./assets/instagram.svg";

const MAX_CATEGORY_SCORE = 6;
const TCUP_COLORS = ["#70CDE9", "#D972B7", "#FFB12F"];

class Summary extends Component {
  constructor(props) {
    super(props);

    const isMobile = window.innerWidth < 767;
    this.state = {
      isMobile,
      insightOpened: false,
    };

    this.onResize = this.onResize.bind(this);
  }

  async componentDidMount() {
    window.addEventListener("resize", this.onResize, false);

    try {
      const uuid = localStorage.getItem(
        `${this.props.sessionId}_${LocalStorageProperties.UUID}`
      );
      const promises = [];
      let categories = null,
        answers = null,
        module = null,
        badge = null;
      promises.push(
        Api.getAllCategories().then((result) => (categories = result))
      );
      promises.push(
        Api.getSessionAnswers(uuid, this.props.sessionId).then(
          async (result) => {
            answers = result?.answers;
            if (result?.badge) {
              badge = {
                url: await Storage.get(
                  `checkup/${result?.uuid}/${result?.badge}`
                ),
                visible: true,
              };
            }
            if (result?.module) {
              module = {
                id: result?.module_id,
                fileName: result?.module,
                url: await Storage.get(
                  `checkup/${result?.uuid}/${result?.module}`
                ),
              };
            }
          }
        )
      );
      promises.push(
        Api.getRecognitionLevel(uuid)
          .then((recognition) => {
            Storage.get(recognition.image_url).then((url) => {
              fetch(url).then(async (response) => {
                this.setState({
                  recognition,
                  recognitionImageUrl: await response.json(),
                });
              });
            });
          })
          .catch(() => this.setState({ recognition: null }))
      );

      await Promise.all(promises);

      this.setState({
        categories,
        answers: answers,
        module,
        badge,
      });
    } catch (err) {
      handleError(err);
      this.props.history.push("/portal");
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.onResize, false);
  }

  onResize() {
    const isMobile = window.innerWidth < 767;
    this.setState({
      isMobile,
    });
  }

  findCategoryBySubcategoryId(categories, subcategoryId) {
    const foundCategory = categories.find((category) => {
      return category.Subcategories.find(
        (subcategory) => subcategory.id === subcategoryId
      );
    });
    return foundCategory ? foundCategory.id : null;
  }

  getCategoryScore(categoryId, categories, answers) {
    if (this.state.answers && this.state.answers.length !== 0) {
      const categoryAnswers = answers.filter(
        (answer) =>
          categoryId ===
          this.findCategoryBySubcategoryId(categories, answer.subcategory_id)
      );
      const score = categoryAnswers
        .map((answer) => answer.calculated_value)
        .reduce((x, y) => x + y);
      return (score / MAX_CATEGORY_SCORE) * 100;
    } else {
      return 0;
    }
  }

  getCategoriesScores(categories, answers) {
    return categories?.map((category) => ({
      id: category.id,
      title: category.name,
      value: this.getCategoryScore(category.id, categories, answers),
      color: TCUP_COLORS[category.id - 1],
      weight: category.weight.weight,
    }));
  }

  renderHeader = () => (
    <div className={styles.header}>
      <h1>Well done</h1>
      <div className={styles.subheader}>
        Let’s have a look at how our blend is shaping up.
      </div>
    </div>
  );

  renderContent = (scores) => (
    <div className={styles.cup}>
      <div>
        <TCup
          scores={scores}
          recognitionImage={this.state.recognitionImageUrl}
        />
        <div className={styles.legend}>
          <div className={styles.health}>Health</div>
          <div className={styles.happiness}>Happiness</div>
          <div className={styles.success}>Success</div>
        </div>
      </div>
    </div>
  );

  isLoading = () =>
    !this.state.categories ||
    !this.state.answers ||
    this.state.recognition === undefined ||
    this.state.module === undefined ||
    this.state.badge === undefined;

  notAnsweredQuestions = () =>
    !isEmpty([this.state.categories, this.state.answers].filter(isNull));

  storeDownloadEvent = () => {
    const uuid = localStorage.getItem(
      `${this.props.sessionId}_${LocalStorageProperties.UUID}`
    );
    Api.saveUserEvent(
      "DOWNLOAD MODULE",
      uuid,
      this.props.sessionId,
      this.state.module.id
    );
  };

  renderModule = (module) => (
    <div className={styles.module}>
      <div>
        Well done for taking the time to reflect on the areas that impact your
        health, happiness and success.
      </div>
      <br />
      <div>
        <i>
          "... when we are self-aware, it becomes easier for us to develop
          self-love..."
        </i>{" "}
        - Lau Tzu
      </div>
      <br />
      <div>
        Congratulations! You have unlocked today's wellbeing module which is
        designed to help 'top up your blend' -
        <a
          href={module?.url}
          target="_blank"
          download
          rel="noopener noreferrer"
          onClick={this.storeDownloadEvent}
        >
          DOWNLOAD
        </a>
      </div>
      <br />
      <div>
        <span className={styles.health}>He</span>
        <span className={styles.happiness}>Ha</span>
        <span className={styles.success}>Su</span>
      </div>
      <div>
        T-Cup Team <i>empowered by your Organisation</i>
      </div>
    </div>
  );

  closeBadgeModal = () => {
    const { badge } = this.state;
    badge.visible = false;
    this.setState({ badge });
  };

  renderBadge = (badge) => {
    if (!badge?.visible) {
      return;
    }
    return (
      <div className={styles.badge}>
        <div className={styles.container}>
          <div className={styles.controls} onClick={this.closeBadgeModal}>
            Close&nbsp;
            <CloseCircleOutlined />
          </div>
          <img alt="" src={badge.url} />
        </div>
      </div>
    );
  };

  renderSocialMedia = () => (
    <div className={styles.social}>
      <div className={styles.header}>Follow us on social media:</div>
      <div className={styles.links}>
        <a
          href="https://www.facebook.com/TCupcommunity/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img alt="facebook" src={facebook} />
        </a>
        <a
          href="https://www.linkedin.com/company/t-cup-app/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img alt="linkedin" src={linkedin} />
        </a>
        <a
          href="https://www.instagram.com/tcup_app/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img alt="instagram" src={instagram} />
        </a>
      </div>
    </div>
  );

  render() {
    if (this.isLoading()) {
      return <Spinner />;
    }
    if (this.notAnsweredQuestions()) {
      notificationWarning("You have to answer questions first");
      return <Redirect to="/questions" />;
    }
    const scores = this.getCategoriesScores(
      this.state.categories,
      this.state.answers
    );
    const { module, badge } = this.state;
    if (this.props.isMobile) {
      return (
        <div className={styles.summary}>
          <div className={styles.layout}>
            {this.renderContent(scores)}
            {this.renderHeader()}
            {module && this.renderModule(module)}
            {this.renderSocialMedia()}
            {this.renderBadge(badge)}
          </div>
        </div>
      );
    }
    return (
      <div className={styles.summary}>
        <div className={styles.layout}>
          <div className={styles.left}>
            <div className={styles.content}>
              {this.renderHeader()}
              <Divider />
              {module && this.renderModule(module)}
              {module && <Divider />}
              {this.renderSocialMedia()}
            </div>
          </div>
          <div className={styles.right}>{this.renderContent(scores)}</div>
        </div>
        {this.renderBadge(badge)}
      </div>
    );
  }
}

const mapStateToProps = ({ width }) => ({
  isMobile: width < 960,
});

export default sizes(mapStateToProps)(withSessionId(Summary));
