import styles from './App.module.less';
import theme from './AuthenticatorTheme';

import React, {Component} from 'react';
import {Authenticator} from "aws-amplify-react";
import {withRouter} from "react-router";
import AuthWrapper from "./auth/AuthWrapper";
import withSessionId from "../redux/hoc/withSessionId";

class App extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    onStateChange = (authState, _) => {
        this.setState({authState})
    }

    render() {
        return (
            <div className={styles.app}>
                <Authenticator
                    hideDefault={true}
                    theme={theme}
                    children={[]}
                >
                    <AuthWrapper
                        authState={this.state.authState}
                        onStateChange={this.onStateChange}
                    />
                </Authenticator>
            </div>
        );
    }

}

export default withRouter(withSessionId(App));
