import React from "react";
import {Col, Row} from 'antd';

class CheckboxGroup extends React.Component {
    constructor(props) {
      super(props);
      this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    }
  
    handleCheckboxChange(event) {
      const selectedCheckboxes = this.props.selectedCheckboxes;
      if (event.target.checked) {
        selectedCheckboxes.push(event.target.value);
      } else {
        selectedCheckboxes.splice(selectedCheckboxes.indexOf(event.target.value), 1);
      }
      this.props.setSelectedCheckboxes(selectedCheckboxes)
    }
  
    render() {
      return (
        <div>
          <Row>
            {this.props.checkboxes.map((checkbox, index) =>  {
              return (
                  <Col xl={12} lg={12} key={index}>
                    <label key={index} style={{marginRight: '10px', opacity: 1,  fontWeight: 500}}>
                    <input
                        type="checkbox"
                        style={{marginRight: '10px'}}
                        value={checkbox.value}
                        checked={this.props.selectedCheckboxes.find((cehckboxData) => cehckboxData === checkbox.value)}
                        onChange={this.handleCheckboxChange}
                    />
                    {checkbox.label}
                    </label>
                  </Col>
                
              )} 
            )}
          </Row>
        </div>
      );
    }
  }

  export default CheckboxGroup;