const FindHierarchyNode = (data, key, callback) => {
        data.forEach((item, index, arr) => {
        if (item.key === key || item.id === key) {
            return callback(item, index, arr);
        }
        if (item.children) {
            return FindHierarchyNode(item.children, key, callback);
        }
        }); 
    };

export default FindHierarchyNode;