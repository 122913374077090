import styles from './AuthWrapper.module.less';

import React, {Component} from "react";
import CustomSignIn from "./CustomSignIn";
import InternalApp from "../InternalApp";
import Spinner from "../utils/spinner/Spinner";

export default class AuthWrapper extends Component {

    renderBody = () => {
        if (this.props.authState === 'signIn') {
            return (
                <CustomSignIn
                    authState={this.props.authState}
                    onStateChange={this.props.onStateChange}
                />
            )
        } else {
            return (
                <InternalApp
                    authState={this.props.authState}
                    onStateChange={this.props.onStateChange}
                />
            )
        }
    }

    render() {
        if (this.props.authState === 'loading') {
            return <Spinner />
        }
        return (
            <div className={styles.wrapper}>
                {this.renderBody()}
            </div>
        );
    }

}