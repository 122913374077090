import {Spin} from 'antd';
import {find} from "lodash";

import React, {Component} from 'react';
import {withRouter} from "react-router";
import withCategories from "../../redux/hoc/withCategories";
import withSessionId from "../../redux/hoc/withSessionId";
import Api from '../api/Api';
import LocalStorageProperties from "../utils/LocalStorageProperties";
import Spinner, {Indicator} from '../utils/spinner/Spinner';
import styles from './QuestionsCarousel.module.less';
import QuestionView from './QuestionView';

class QuestionsCarousel extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isMobile: window.innerWidth < 767,
            userData: props.userData,
            answers: [],
            currentIndex: 0,
            savingAnswers: false
        };

        this.onResize = this.onResize.bind(this);
    }

    componentDidMount() {
        window.addEventListener('resize', this.onResize, false);
        this.getDailyQuestions();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.onResize, false);
    }

    onResize() {
        this.setState({
            isMobile: window.innerWidth < 767
        });
    }

    getDailyQuestions() {
        Api.getDailyQuestions()
            .then(questions => {
                const extraQuestion = questions.find(q => q.question_type === 'CHECKUP_EXTRA');
                this.setState({
                    questions,
                    extraQuestionId: extraQuestion.id,
                    answers: [],
                    currentIndex: 0,
                })
            })
            .catch((err) => {
                if (err.response && err.response.status === 422) {
                    this.props.history.push('/summary');
                } else {
                    this.props.history.push('/dashboard');
                }
            });
    }

    isSavingAnswers(state) {
        this.setState({
            savingAnswers: state
        });
    }

    submitAnswers = (answers, extraAnswer) => {
        this.isSavingAnswers(true);
        const uuid = localStorage.getItem(`${this.props.sessionId}_${LocalStorageProperties.UUID}`)
        Api.saveAnswers(uuid, this.props.sessionId, answers, extraAnswer)
            .then(() => {
                this.props.history.push('/summary');
            })
            .catch(e => {
                this.isSavingAnswers(false);
            });
    };
    selectAnswer = async (questionId, questionAnswer) => {
        if (!this.state.savingAnswers) {
            if (this.state.extraQuestionId === questionId) {
                this.saveExtraAnswer(questionId, questionAnswer);
            } else {
                this.saveAnswer(questionId, questionAnswer);
            }

            if (this.state.answers.length === 9 && this.state.extraAnswer) {
                this.submitAnswers(this.state.answers, this.state.extraAnswer)
            } else {
                const nextIndex = this.state.currentIndex + 1;
                if (nextIndex < 10) {
                    this.changeQuestion(nextIndex);
                }
            }
        }
    };

    saveAnswer(questionId, questionAnswer) {
        const answers = this.state.answers;
        const found = answers.find(ans => ans.questionId === questionId);
        if (found) {
            found.value = questionAnswer;
        } else {
            answers.push({questionId: questionId, value: questionAnswer});
        }
        this.setState({
            answers
        });
    }

    saveExtraAnswer(questionId, questionAnswer) {
        this.setState({
            extraAnswer: {
                questionId: questionId,
                value: questionAnswer
            }
        });
    }

    prev = () => {
        const current = this.state.currentIndex;
        const prev = current === 0 ? this.state.questions.length - 1 : current - 1;
        this.changeQuestion(prev);
    };

    next = () => {
        const current = this.state.currentIndex;
        const next = current === this.state.questions.length - 1 ? 0 : current + 1;
        this.changeQuestion(next);
    };

    changeQuestion = (index) => {
        this.setState({
            currentIndex: index,
        });
    };

    renderQuestions(currentIndex) {
        if (this.state.questions.length !== 0) {
            return (
                this.state.questions.map((question, key) => (
                    <QuestionView
                        key={question.id}
                        index={key}
                        question={question}
                        subcategory={find(this.props.categories.flatMap(c => c.Subcategories), {id: question.subcategory_id})}
                        callback={this.selectAnswer}
                        prev={this.prev}
                        next={this.next}
                        visible={this.state.questions[currentIndex].id === question.id}
                    />
                ))
            );
        }
    }

    render() {
        if (!this.state.questions) {
            return (
                <Spinner/>
            );
        }
        const currentIndex = this.state.currentIndex;
        return (
            <div className={styles.layout}>
                <Spin spinning={this.state.savingAnswers} size='large' indicator={<Indicator/>}>
                    <div className={styles.content}>
                        {this.renderQuestions(currentIndex)}
                    </div>
                </Spin>
            </div>
        );
    }
}

export default withRouter(withCategories(withSessionId(QuestionsCarousel)));