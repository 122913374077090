import withSessionId from "../../redux/hoc/withSessionId";
import styles from './CustomSignIn.module.less';

import React from "react";
import {Auth} from "aws-amplify";
import {SignIn} from "aws-amplify-react";
import {Button, Form, Input, Spin} from "antd";
import {notificationError} from "../utils/notification/Notification";
import logo from './CheckUp_LOGO.svg';
import Api from '../api/Api';

class CustomSignIn extends SignIn {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false
        }

        this._validAuthStates = ["signIn", "signedOut", "signedUp"];
    }

    onFinish = async (values) => {
        const username = this.props.sessionId;

        this.setState({isLoading: true});      
        Api.getAccessData().then(result => {
            const password = Buffer.from(result.value, 'base64').toString('ascii').trim();
            Auth.signIn(username, password)
                .then(() => this.props.onStateChange('signedIn', {}))
                .catch(err => {
                    this.setState({isLoading: false});
                    notificationError(err.message);
                });
            }).catch(err => {
                this.setState({isLoading: false});
                notificationError(err.message);
            })
    }

    renderPassword = () => (
        <Form.Item
            label='Password'
            name='password'
            className={styles.password}
            rules={[{required: true, message: 'Password is required!'}]}
        >
            <Input.Password placeholder='Password'/>
        </Form.Item>
    );

    renderSubmit = () => (
        <Form.Item className={styles.submit}>
            <Button htmlType='submit'>
                Let's go
            </Button>
        </Form.Item>
    );

    showComponent = () => (
        <div className={styles.customSignIn}>
            <Spin spinning={this.state.isLoading} size='large' wrapperClassName={styles.spinner}>
                <Form layout='vertical' onFinish={this.onFinish}>
                    <img alt='' src={logo}/>
                    {this.renderSubmit()}
                </Form>
            </Spin>
        </div>
    );
}

export default withSessionId(CustomSignIn);