import React from "react";
import {Auth} from "aws-amplify";
import {isEmpty} from "lodash";
import {Redirect, Route, Switch, withRouter} from "react-router";
import {MemoryRouter} from "react-router-dom";
import withSessionId from "../redux/hoc/withSessionId";
import FirstLoginPage from "./firstLogin/FirstLoginPage";
import QuestionsCarousel from "./questions/QuestionsCarousel";
import Summary from "./summary/Summary";
import Spinner from "./utils/spinner/Spinner";
import LocalStorageProperties from "./utils/LocalStorageProperties";
import Api from "./api/Api";

const REQUIRED_PROPS = [
    LocalStorageProperties.UUID,
    LocalStorageProperties.BIRTHYEAR,
    LocalStorageProperties.OCCUPATION_ID,
    LocalStorageProperties.GENDER_ID,
    LocalStorageProperties.LOCATION_ID,
    LocalStorageProperties.TEAM_ID
];

class InternalApp extends React.Component {

    constructor(props) {
        super(props);

        this.state = {};
    }

    componentDidMount() {
        window.addEventListener("beforeunload", this.onUnload, false);

        Auth.currentAuthenticatedUser()
            .then(cognitoUser => this.setState({cognitoUser}))
            .catch(() => { /* do nothing */ });

        const uuid = localStorage.getItem(`${this.props.sessionId}_${LocalStorageProperties.UUID}`)
        Api.getSessionAnswers(uuid)
            .then(result => this.setState({session: result}))
            .catch(() => { /* do nothing */ });
    }

    componentWillUnmount() {
        window.removeEventListener("beforeunload", this.onUnload, false);
    }

    onUnload = async (e) => {
        e.preventDefault();
        await Auth.signOut();
        return e.returnValue = 'Are you sure?';
    }

    authenticatedRoutes = (
        <Switch>
            <Route exact path={`/`} render={() => {
                if (this.shouldDisplayFirstLogin()) {
                    return <Redirect to={`/profile`}/>
                }
                if (this.shouldDisplaySummary()) {
                    return <Redirect to={`/summary`} />
                }
                return <Redirect to={`/questions`}/>
            }}/>
            <Route path={`/profile`} component={FirstLoginPage}/>
            <Route path={`/questions`} component={QuestionsCarousel}/>
            <Route path={`/summary`} component={Summary} />
        </Switch>
    );

    shouldDisplayFirstLogin = () => {
        for (const prop of REQUIRED_PROPS) {
            if (isEmpty(localStorage.getItem(`${this.props.sessionId}_${prop}`))) {
                return true;
            }
        }
        return false;
    }

    shouldDisplaySummary = () => {
        return !isEmpty(this.state.session?.answers);
    }

    render() {
        if (this.props.authState !== 'signedIn') {
            return null;
        }
        if (!this.state.cognitoUser || !this.state.session) {
            return <Spinner/>
        }
        return (
            <MemoryRouter>
                {this.authenticatedRoutes}
            </MemoryRouter>
        );
    }
}

export default withRouter(withSessionId(InternalApp));