const LocalStorageProperties = {
    SESSION_ID: 'sessionId',
    UUID: 'uuid',
    BIRTHYEAR: 'birthyear',
    GENDER_ID: 'gender_id',
    OCCUPATION_ID: 'occupation_id',
    FITNESS_LEVEL_ID: 'fitness_level_id',
    TEAM_ID: 'team_id',
    LOCATION_ID: 'location_id'
};

Object.freeze(LocalStorageProperties);

export default LocalStorageProperties;