import {notificationError} from "../notification/Notification";

const handleError = (err) => {
    const response = err.response;
    if (response && response.data) {
        if (response.data.errors) {
            const firstError = response.data.errors[0];
            notificationError(`${firstError.message} : ${firstError.value}`);
        }
        if (response.data.message) {
            notificationError(response.data.message);
        }
    } else {
        if (err.message) {
            notificationError(err.message);
        }
    }
};

export default handleError;