import {SESSION_ID} from '../actions/types';

const initialState = {
    sessionId: null
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SESSION_ID.FETCH_SESSION_ID_START:
            return {
                ...state
            };

        case SESSION_ID.FETCH_SESSION_ID_SUCCESS:
            return {
                ...state,
                sessionId: action.payload.sessionId
            };

        default: {
            return state;
        }
    }
}