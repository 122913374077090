import styles from './CustomSelect.module.less';

import React from 'react';
import {Select} from 'antd';

const CustomSelect = (props) => {

    return (
        <Select
            onChange={props.onChange}
            placeholder={props.placeholder}
            tabIndex={props.tabIndex}
            className={styles.select}
            defaultValue={props.value}
            size={props.size || "large"}
            options={props.options.map(option => ({label: option.title || option.name, value: option.id}))}
        />
    );

}

export default CustomSelect;