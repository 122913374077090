import styles from './QuestionView.module.less';

import React, {Component} from 'react';
import {Button, Divider, Icon, Modal, Spin} from "antd";
import {Storage} from "aws-amplify";
import Spinner from "../utils/spinner/Spinner";
import MobileAnswers from "./mobileAnswers/MobileAnswers";
import QuestionDirection from "./QuestionDirection";

const {success} = Modal;

const NO_OF_ANSWERS = 7;
const COLORS = ['#A61C3F', '#EC4857', '#FF7D57', '#FED019', '#CDD771', '#93D76A', '#56C484'];

class QuestionView extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isMobile: window.innerWidth < 960,
            index: props.index,
            question: props.question,
            direction: props.question.direction === QuestionDirection.RANDOM ? Object.values(QuestionDirection)[Math.round(Math.random())] : props.question.direction,
            visible: typeof props.visible !== 'undefined' ? props.visible : true,
            selectedAnswer: 3,
            currentImage: 3,
            isLoading: true,
            isSaving: false
        };

        this.onResize = this.onResize.bind(this);
    }

    async componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            ...nextProps
        });
    }

    async componentDidMount() {
        window.addEventListener('resize', this.onResize, false);
        await this.getImageUrls();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.onResize, false);
    }

    onResize() {
        this.setState({
            isMobile: window.innerWidth < 960
        });
    }

    async getImageUrls() {
        this.setState({
            isLoading: true
        });

        const result = await Promise.all([
            Storage.get(this.state.question.negative_image_url),
            Storage.get(this.state.question.negative_reveal_image_url),
            Storage.get(this.state.question.negative_second_reveal_image_url),
            Storage.get(this.state.question.positive_second_reveal_image_url),
            Storage.get(this.state.question.positive_second_reveal_image_url),
            Storage.get(this.state.question.positive_reveal_image_url),
            Storage.get(this.state.question.positive_image_url)
        ]);

        this.setState({
            images: result,
            isLoading: false
        });
    }

    answerQuestion = (event) => {
        if (!this.state.isSaving) {
            const button = event.target;
            const value = parseInt(button.value);

            this.setState({
                selectedAnswer: value,
                currentImage: value
            });

            if (this.props.callback) {
                setTimeout(() => {
                    this.setState({
                        isSaving: true
                    });
                    setTimeout(() => {
                        this.props.callback(this.state.question.id, value);
                        this.setState({
                            isSaving: false
                        });
                    }, 250);
                }, this.state.isMobile ? 750 : 0);
            }
        }
    };

    nextQuestion = () => {
        if (!this.state.isSaving) {
            this.setState({
                isSaving: true
            });

            if (this.props.callback) {
                setTimeout(() => {
                    this.props.callback(this.state.question.id, this.state.selectedAnswer);
                    this.setState({
                        isSaving: false
                    });
                }, 250);
            } else {
                this.setState({
                    isSaving: false
                });
            }
        }
    }

    selectAnswer = (value) => {
        this.setState({
            selectedAnswer: value,
            currentImage: value
        })
    }

    answerButtons() {
        if (this.state.isMobile) {
            return (
                <MobileAnswers
                    selected={this.state.selectedAnswer}
                    onChange={this.selectAnswer}
                />
            );
        }
        return this.renderDesktopAnswers();
    }

    renderDesktopAnswers() {
        const buttons = [];
        for (let i = 0; i < NO_OF_ANSWERS; i++) {
            buttons.push({
                id: i,
                value: i,
                className: `${styles.roundButton}`
            });
        }
        return buttons.map(button => {
            const className = `${button.className} ${this.state.selectedAnswer === button.value ? 'active' : ''}`;
            return <button key={button.id}
                           id={button.id}
                           value={button.value}
                           className={className}
                           style={{backgroundColor: (this.state.hoveredImage >= button.value) || (this.state.hoveredImage === undefined && this.state.currentImage >= button.value) ? COLORS[button.id] : '#EDEEF5'}}
                           onMouseOver={() => this.setCurrentImage(button.id)}
                           onMouseOut={() => this.setCurrentImage(this.state.selectedAnswer)}
                           onClick={this.answerQuestion}
            />
        });
    }

    setCurrentImage = (index) => {
        this.setState({
            hoveredImage: index || 0,
        })
    }

    showExplanation = () => {
        success({
            content: this.state.question.explanation,
            centered: true,
            className: styles.info,
            destroyOnClose: true,
            width: window.innerWidth < 769 ? '90%' : '50%',
            icon: false,
            okButtonProps: {block: true}
        });
    };

    renderHelpButton = () => (
        <Button
            type='link'
            onClick={this.showExplanation}
        >Tell me more...</Button>
    );

    renderImages() {
        return this.state.images.map((image, key) => {
            const selectedOrHovered = this.state.hoveredImage === key || (this.state.hoveredImage === undefined && this.state.currentImage === key);
            const imageStyle = {
                backgroundImage: `url(${image})`
            };
            if (key === 3 && selectedOrHovered) {
                imageStyle.opacity = 0.5;
                imageStyle.filter = 'grayscale(100%)'
            }
            return (
                <div key={key}
                     className={`${styles.image} ${selectedOrHovered ? styles.visible : styles.hidden}`}
                     style={imageStyle}
                />
            );
        });
    }

    render() {
        if (!this.state.visible) {
            return (<div/>);
        }
        if (!this.state.images) {
            return (
                <Spinner/>
            );
        }
        return (
            <div className={styles.question}>
                <Spin spinning={this.state.isSaving} size='large'>
                    <div className={styles.content}>
                        <div className={styles.left}>
                            <div className={styles.layout}>
                                <div className={styles.header}>
                                    <div>Question {(this.state.index + 1) || 1} of 10</div>
                                    <div>{this.renderHelpButton()}</div>
                                </div>
                                {this.props.subcategory &&
                                <div className={styles.category}>
                                    Reflecting on: <span>{this.props.subcategory.name}</span>
                                </div>}
                                <div className={styles.text}>{this.state.question.text}</div>
                                <div className={styles.answers}>
                                    {this.answerButtons()}
                                </div>
                                <div className={styles.labels}>
                                    <div>Needs Work</div>
                                    <div>Thriving</div>
                                </div>
                                <Divider/>
                                <div className={styles.navigation}>
                                    <Button disabled={this.state.index === 0}
                                            className={styles.prev}
                                            type='link'
                                            onClick={this.props.prev}
                                    >
                                        <Icon type="left"/>Previous
                                        Question
                                    </Button>
                                    <Button
                                        className={styles.next}
                                        type='primary'
                                        shape='round'
                                        onClick={this.nextQuestion}
                                    >
                                        {this.state.index === 9 ? 'Submit Answers' : 'Next Question'}<Icon type="right"/>
                                    </Button>
                                </div>
                            </div>
                        </div>
                        <div className={styles.right}>
                            {this.renderImages()}
                        </div>
                    </div>
                </Spin>
            </div>
        );
    }
}

export default QuestionView;