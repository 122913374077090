import { API } from "aws-amplify";

const API_USERS_NAME = "usersApi";
const API_QUESTIONS_NAME = "questionsApi";
const API_USERS_NO_AUTH = "userApiNoAuth"

export default class Api {
  static getDailyQuestions() {
    return API.get(API_QUESTIONS_NAME, `/questions`);
  }

  static saveAnswers(uuid, sessionId, answers, extraAnswer) {
    const data = {
      body: {
        uuid,
        sessionId,
        answers,
        extraAnswer,
      },
    };
    return API.post(API_QUESTIONS_NAME, "/questions/answer", data);
  }

  static saveUserEvent(eventType, uuid, sessionId, moduleId) {
    const data = {
      body: {
        eventType,
        uuid,
        sessionId,
        moduleId,
      },
    };

    return API.post(API_QUESTIONS_NAME, "/user-events", data);
  }

  static getAllCategories() {
    return API.get(API_QUESTIONS_NAME, "/categories");
  }

  static getSessionAnswers(uuid) {
    return API.get(API_QUESTIONS_NAME, `/answers/${uuid}`);
  }

  static getProfileFeatures(sessionId) {
    return API.get(API_USERS_NAME, `/session/${sessionId}/profile/features`);
  }

  static getAccessData() {
    return API.get(API_USERS_NO_AUTH, "/access/data");
  }

  static createUser(userData) {
    const data = {
      body: {
        occupation_id: userData.occupation_id,
        fitness_level_id: userData.fitness_level_id,
        gender_id: userData.gender_id,
        location_id: userData.location_id,
        team_id: userData.team_id,
        birthyear: userData.birthyear,
        ethnicity_id: userData.ethnicity_id,
        tags: userData.tags,
        session_id: userData.session_id
      },
    };
    console.log(data)
    return API.post(API_USERS_NAME, `/user`, data);
  }

  static getRecognitionLevel(sessionId) {
    return API.get(API_QUESTIONS_NAME, `/recognition/${sessionId}`);
  }
}
