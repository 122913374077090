import styles from "./FirstLoginPage.module.less";

import React, { Component } from "react";
import { Button, Col, Form, Row, Spin, Select } from "antd";
import moment from "moment";
import { withRouter } from "react-router";
import withSessionId from "../../redux/hoc/withSessionId";
import Api from "../api/Api";
import YearPicker from "./userForm/yearPicker/YearPicker";
import CustomSelect from "./userForm/customSelect/CustomSelect";
import Spinner, { Indicator } from "../utils/spinner/Spinner";
import handleError from "../utils/handleError/HandleError";
import LocalStorageProperties from "../utils/LocalStorageProperties";
import CheckboxGroup from "../utils/Hierarchy/CheckboxGroup"
import FlatToHierarchy from "../utils/Hierarchy/FlatToHierarchy"
import FindHierarchyNode from "../utils/Hierarchy/FindHierarchyNode"
import HierarchySelect from "../utils/Hierarchy/HierarchySelect"

class FirstLoginPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showOcupationInfo: false,
      showGenderInfo: false,
      showTeamInfo: false,
      showLocationInfo: false,
      showEthnicityInfo: false,
      showBirthYearInfo: false,
      showFitnessInfo: false,
      isLoading: false,
      birthyear:
        parseInt(
          localStorage.getItem(
            `${this.props.sessionId}_${LocalStorageProperties.BIRTHYEAR}`
          )
        ) || null,
      gender_id:
        parseInt(
          localStorage.getItem(
            `${this.props.sessionId}_${LocalStorageProperties.GENDER_ID}`
          )
        ) || null,
      occupation_id:
        parseInt(
          localStorage.getItem(
            `${this.props.sessionId}_${LocalStorageProperties.OCCUPATION_ID}`
          )
        ) || null,
      fitness_level_id:
        parseInt(
          localStorage.getItem(
            `${this.props.sessionId}_${LocalStorageProperties.FITNESS_LEVEL_ID}`
          )
        ) || null,
      team_id:
        parseInt(
          localStorage.getItem(
            `${this.props.sessionId}_${LocalStorageProperties.TEAM_ID}`
          )
        ) || null,
      location_id:
        parseInt(
          localStorage.getItem(
            `${this.props.sessionId}_${LocalStorageProperties.LOCATION_ID}`
          )
        ) || null,
      ethnicity_id:
        parseInt(
          localStorage.getItem(
            `${this.props.sessionId}_${LocalStorageProperties.ETHNICITY_ID}`
          )
        ) || null,
      tags: [],
    };
  }

  async componentDidMount() {
    const profileFeatures = await Api.getProfileFeatures(this.props.sessionId);
    this.setState({ profileFeatures });
    if(profileFeatures.teams.length === 1) {
      this.setState({ team_id: profileFeatures.teams[0].id });
    }
  }

  handleSubmit = (values) => {
    this.setState({ isLoading: true });
    if(!this.state.team_id) {
      this.setState({ isLoading: false });
      handleError({message: 'Please select your team!'});
      return;
    }
    const calcBirthyear = moment().year() - values.ageGroup - 1
    const props = {
      birthyear: calcBirthyear,
      gender_id: values.gender_id,
      occupation_id: values.occupation_id,
      team_id: this.state.team_id,
      location_id: values.location_id,
      ethnicity_id: values.ethnicity_id,
      tags: this.state.tags ? this.state.tags.toString() : '',
      session_id: this.props.sessionId
    };
    Api.createUser(props)
      .then((uuid) => {
        localStorage.setItem(
          `${this.props.sessionId}_${LocalStorageProperties.UUID}`,
          uuid
        );
        for (const [key, value] of Object.entries(props)) {
          localStorage.setItem(`${this.props.sessionId}_${key}`, value);
        }
        this.props.history.push("/questions");
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        handleError(error);
      });
  };

  renderOccupation = (value, occupations) => (
    <Form.Item
      label="Which of the below best describes what you do?"
      name="occupation_id"
      initialValue={value}
      rules={[{ required: true, message: "Your occupation is required" }]}
      style={{marginBottom: 5}}
    >
      <CustomSelect
        tabIndex={1}
        placeholder="Occupation"
        options={occupations}
      />
    </Form.Item>
  );

  renderGender = (value, genders) => (
    <Form.Item
      label="What is your gender?"
      name="gender_id"
      initialValue={value}
      rules={[{ required: true, message: "Your gender is required" }]}
      style={{marginBottom: 5}}
    >
      <CustomSelect tabIndex={2} placeholder="Gender" options={genders} />
    </Form.Item>
  );

  renderTeamHierarchy = (value, teams) => {
    let teamHierarchy = FlatToHierarchy(teams);
    let initialTeam = teams.length === 1 ? '0-0' : undefined;
    FindHierarchyNode(teamHierarchy, value, item => initialTeam = item.key )

    return (
        <Form.Item label='What area of your company do you work within?' rules={[{ required: true, message: "Your team is required" }]}
          style={{marginBottom: 5}} initialValue={value} required>
                <Select placeholder='Team' size='large' disabled={true} style={{ visibility: 'hidden', position: 'absolute'}}>
                    {teams && teams.map((option, key) =>
                        <Select.Option
                            key={key}
                            value={option.id}>{option.name}
                        </Select.Option>
                    )}
                </Select>
            <HierarchySelect 
                treeData={teamHierarchy}
                handleSelect={(team) => { team ? this.setState({team_id: team.id, selectedKey: team.eventKey}) : this.setState()}}
                teamId={this.state.team_id}
                selectedKey={this.state.selectedKey ? this.state.selectedKey : initialTeam }
                treeStyle={styles.treeStyle}
                treeNodeStyle={styles.treeNode}
            />
        </Form.Item>
  );}

  renderTeam = (value, teams) => (
    <Form.Item
      label="What area of your company do you work within?"
      name="team_id"
      initialValue={value}
      rules={[{ required: true, message: "Your team is required" }]}
      style={{marginBottom: 5}}
    >
      <CustomSelect tabIndex={3} placeholder="Team" options={teams} />
    </Form.Item>
  );

  renderLocation = (value, locations) => (
    <Form.Item
      label="What is your location?"
      name="location_id"
      initialValue={value}
      rules={[{ required: true, message: "Your location is required" }]}
      style={{marginBottom: 5}}
    >
      <CustomSelect tabIndex={4} placeholder="Location" options={locations} />
    </Form.Item>
  );

  renderEthnicity = (value, ethnicities) => (
    <Form.Item
      label="What is your ethnic group?"
      name="ethnicity_id"
      initialValue={value}
      rules={[{ required: true, message: "Your ethnicity is required" }]}
      style={{marginBottom: 5}}
    >
      <CustomSelect tabIndex={5} placeholder="Ethnicity" options={ethnicities} />
    </Form.Item>
  );

  renderBirthYear = (value) => (
    <Form.Item
      label="What is your age group?"
      name="ageGroup"
      initialValue={value}
      rules={[{ required: true, message: "Your age group is required" }]}
      style={{marginBottom: 5}}
    >
      <YearPicker
        tabIndex={6}
        className={styles.yearPicker}
        size="large"
        start={moment()}
        end={moment().subtract(120, "year")}
      />
    </Form.Item>
  );

  renderTags = (allTags) => {
        
    return (
            <Form.Item label='Check the tags as you want' align="center" initialValue={this.state.tags}>
                <CheckboxGroup 
                    checkboxes={allTags}
                    selectedCheckboxes={this.state.tags}
                    setSelectedCheckboxes={(selectedValues) => this.setState({tags: selectedValues})}
                />
            </Form.Item>
        )
  }


  renderSubmit = () => (
    <Form.Item className={styles.submit}>
      <Button type="primary" htmlType="submit" shape="round" tabIndex={8}>
        CHECK IN
      </Button>
    </Form.Item>
  );

  render() {
    if (!this.state.profileFeatures) {
      return <Spinner />;
    }
    const {
      birthyear,
      occupation_id,
      gender_id,
      team_id,
      location_id,
      fitness_level_id,
      profileFeatures
    } = this.state;
    const { occupations, genders, teams, locations, fitnessLevels, ethnicities, tags } =
      profileFeatures;
    return (
      <div className={styles.container}>
        <Spin
          spinning={this.state.isLoading}
          size="large"
          indicator={<Indicator />}
        >
          <Row type="flex" justify="center">
            <Col md={12} span={20}>
              <div className={styles.title}>Welcome to CheckUp</div>
              <div className={styles.subtitle}>
                It would be great if you could tell us a little more about
                yourself, so we can give you the most helpful tips and insights…
              </div>
            </Col>
          </Row>
          <Form
            layout="vertical"
            onFinish={this.handleSubmit}
            hideRequiredMark
            colon={false}
          >
            <div className={styles.row}>
              <div className={styles.col}>
                {this.renderOccupation(occupation_id, occupations)}
                <div 
                  className={styles.moreInfoButton} 
                  onClick={() => this.setState({showOcupationInfo: !this.state.showOcupationInfo})}
                >
                More info
                {this.state.showOcupationInfo
                ?
                  <img
                    className={styles.chevron}
                    alt="chevron-up"
                    src="/icons/chevron-up.svg"
                  />
                :
                  <img
                    className={styles.chevron}
                    alt="chevron-down"
                    src="/icons/chevron-down.svg"
                  />
                }
                </div>
              {
                this.state.showOcupationInfo
                && 
                <div className={styles.infoContainer}>
                  We ask for this information to ensure that the content served to you is helpful and relevant to your circumstances. All information gathered is anonymous.
                </div>
              }
              {
                this.state.showGenderInfo && !this.state.showOcupationInfo
                && 
                <div className={styles.infoContainer}>
          
                </div>
              }
              </div>
              <div className={styles.col}>
                {this.renderGender(gender_id, genders)}
                <div 
        className={styles.moreInfoButton} 
        onClick={() => this.setState({showGenderInfo: !this.state.showGenderInfo})}
      >
        More info
        {this.state.showGenderInfo
          ?
          <img
            className={styles.chevron}
            alt="chevron-up"
            src="/icons/chevron-up.svg"
          />
          :
          <img
            className={styles.chevron}
            alt="chevron-down"
            src="/icons/chevron-down.svg"
          />
        }
      </div>
      {
        this.state.showGenderInfo 
        && 
        <div className={styles.infoContainer}>We are committed to diversity, equity and inclusion and we ask for this information to ensure that the content served to you is helpful and relevant. All information gathered is anonymous.</div>
      }
      {
        this.state.showOcupationInfo && !this.state.showGenderInfo
        && 
        <div className={styles.infoContainer}>
          
        </div>
      }
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles.col}>
                {this.renderEthnicity(location_id, ethnicities)}
                <div 
                  className={styles.moreInfoButton} 
                  onClick={() => this.setState({showEthnicityInfo: !this.state.showEthnicityInfo})}
                >
                More info
                {this.state.showEthnicityInfo
                ?
                <img
                  className={styles.chevron}
                  alt="chevron-up"
                  src="/icons/chevron-up.svg"
                />
                :
                <img
                  className={styles.chevron}
                  alt="chevron-down"
                  src="/icons/chevron-down.svg"
                />
                }
                </div>
                {
                  this.state.showEthnicityInfo
                  && 
                  <div className={styles.infoContainer}>We ask for this information in the interests of Diversity and Inclusion to help us identify challenges facing individuals and groups and to ensure that our recommendations remain relevant and useful.</div>
                }
                {
                  this.state.showBirthYearInfo && !this.state.showEthnicityInfo
                  && 
                  <div className={styles.infoContainer}>
          
                  </div>
                }
              </div>
              <div className={styles.col}>
                {this.renderBirthYear(birthyear)}
                <div 
                  className={styles.moreInfoButton} 
                  onClick={() => this.setState({showBirthYearInfo: !this.state.showBirthYearInfo})}
                >
                More info
                {this.state.showBirthYearInfo
                ?
                <img
                  className={styles.chevron}
                  alt="chevron-up"
                  src="/icons/chevron-up.svg"
                />
                :
                <img
                  className={styles.chevron}
                  alt="chevron-down"
                  src="/icons/chevron-down.svg"
                />
                }
                </div>
                {
                  this.state.showBirthYearInfo
                  && 
                  <div className={styles.infoContainer}>We ask for this information to ensure that the content served to you is age-appropriate. All information gathered is anonymous.</div>
                }
                {
                  this.state.showEthnicityInfo && !this.state.showBirthYearInfo
                  && 
                  <div className={styles.infoContainer}>
          
                  </div>
                }
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles.col}>
                {this.renderLocation(location_id, locations)}
                <div 
                  className={styles.moreInfoButton} 
                  onClick={() => this.setState({showLocationInfo: !this.state.showLocationInfo})}
                >
                More info
                {this.state.showLocationInfo
                ?
                <img
                  className={styles.chevron}
                  alt="chevron-up"
                  src="/icons/chevron-up.svg"
                />
                :
                <img
                  className={styles.chevron}
                  alt="chevron-down"
                  src="/icons/chevron-down.svg"
                />
                }
                </div>
                {
                  this.state.showLocationInfo
                  && 
                  <div className={styles.infoContainer}>We ask for this information so that we can track trends in different locations within the company to ensure we can offer the right guidance and support. All information gathered is anonymous.</div>
                }
              </div>
            </div>
            {
                tags.length > 0
                && 
                <div className={styles.row}>
                  <div className={styles.col}>
                    {this.renderTags(tags)}
                  </div>
                </div>
              }
            <div className={styles.row}>
              <div className={styles.col}>
                {this.renderTeamHierarchy(team_id, teams)}
              </div>
            </div>
            <div className={styles.center}>{this.renderSubmit()}</div>
          </Form>
        </Spin>
      </div>
    );
  }
}

export default withRouter(withSessionId(FirstLoginPage));
