import React from "react";
import {Select} from "antd";
import moment from "moment";

export default class YearPicker extends React.Component {

    handleChange = (selected) => {
        this.props.onChange(selected);
    };

    options = () => {
        const options = [
            {label: '10s', value: 10},
            {label: '20s', value: 20},
            {label: '30s', value: 30},
            {label: '40s', value: 40},
            {label: '50s', value: 50},
            {label: '60s', value: 60},
            {label: '70s', value: 70},
            {label: '80s+', value: 80},
        ] 
        
        return options;
    };

    render() {
        return (
            <Select
                {...this.props}
                showSearch
                onChange={this.handleChange}
                placeholder={'Please select your age group ...'}
                options={this.options().map(opt => ({label: opt.label, value: opt.value}))}
            />
        );
    }
};