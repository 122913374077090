import styles from './Notification.module.less';
import {Toast} from "antd-mobile";
import 'antd-mobile/lib/toast/style/index';
import 'antd-mobile/lib/toast/style/css';
import {notification} from "antd";

export const notificationInfo = (message) => {
    window.innerWidth < 720 ?
        Toast.info(message)
        :
        notification['info']({
            message: 'Info',
            description: message,
            className: styles.notification,
            top: findTopPosition()
        });
};

export const notificationSuccess = (message) => {
    window.innerWidth < 720 ?
        Toast.success(message)
        :
        notification['success']({
            message: 'Success',
            description: message,
            className: styles.notification,
            top: findTopPosition(),
        });
};

export const notificationWarning = (message) => {
    window.innerWidth < 720 ?
        Toast.offline(message)
        :
        notification['warning']({
            message: 'Warning',
            description: message,
            className: styles.notification,
            top: findTopPosition()
        });
};

export const notificationError = (message) => {
    window.innerWidth < 720 ?
        Toast.fail(message)
        :
        notification['error']({
            message: 'Error',
            description: message,
            className: styles.notification,
            top: findTopPosition()
        });
};

function findTopPosition() {
    return window.innerWidth < 720 ? 20 : 50;
}
