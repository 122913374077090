import React from 'react';
import {connect} from 'react-redux';
import Spinner from "../../modules/utils/spinner/Spinner";
import * as actions from '../actions';

export default Component => {
    const mapStateToProps = state => {
        return {
            sessionId: state.sessionId.sessionId
        };
    };

    const mapDispatchToProps = dispatch => {
        return {
            onFetchSessionId: () => dispatch(actions.fetchSessionId()),
        };
    };

    class Wrapper extends React.Component {
        componentDidMount() {
            if (this.props.sessionId) {
                return;
            }
            this.props.onFetchSessionId();
        }

        render() {
            if (!this.props.sessionId) {
                return <Spinner/>;
            }
            return (
                <Component {...this.props} />
            );
        }
    }

    return connect(mapStateToProps, mapDispatchToProps)(Wrapper);
};
